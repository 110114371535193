.artwork-details {
  &__artist {
    white-space: pre-line;
    color: gray;
  }
  &__image {
    max-height: 400px;
    max-width: 100%;
    cursor: pointer;
    box-shadow: 0 0.75rem 1.5rem rgba(black, 0.1);
  }
}