.paginator {
  margin: 1rem auto !important;
  .page-item {
    .page-link {
      color: black;

      &:hover {
        color: black;
      }

      &:focus {
        color: black;
        outline: none;
        box-shadow: none;
        background-color: white;
      }
    }

    &.active {
      .page-link {
        background-color: #5b6369 !important;
        border-color: #5b6369 !important;
        color: white;
      }
    }
  }
}