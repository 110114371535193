@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot');
  src: url('./icomoon.eot?#iefix') format('embedded-opentype'), url('./icomoon.woff') format('woff'),
    url('./icomoon.ttf') format('truetype'), url('./icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon', serif;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: '\e900';
}

.icon-close:before {
  content: '\e901';
}
