.artwork-card {
  &__image {
    height: 250px;
    object-fit: contain;
    background-color: black;
    cursor: pointer;
  }

  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 1rem;
  }
}
