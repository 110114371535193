.header {
    &__navbar {
        width: 100%;
    }
    &__title {
        display: inline-block;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
}