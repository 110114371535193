.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  animation: fadeIn linear 100ms;

  &__closable {
    cursor: pointer;
  }

  &__content-container {
    position: relative;
    width: fit-content;
    margin: auto;
    align-items: center;
  }
  &__close-button {
    position: absolute;
    right: -0.8rem;
    top: -0.8rem;
    font-size: 1.4rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__content {
    margin: auto;
    cursor: initial;
    * {
      max-height: 90vh;
      max-width: 90vw;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
